import React from 'react';

import CardinalIcon from '@svg/cardinal.svg';
import { ModuleWrapper } from '../moduleWrapper';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';

const BrandHeading = ({ config, headline }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="text-center">
        <div>
          <CardinalIcon className="mx-auto w-24 text-red fill-current mb-one md:w-32" />
        </div>
        <PortableTextBlock text={headline} />
      </div>
    </ModuleWrapper>
  );
};

export default BrandHeading;
